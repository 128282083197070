@font-face {
    font-family: VCR;
    src: url(../../assets/fonts/VCR_OSD_MONO_1.001.ttf);
  }

/*--- Project Overview (Left Container)---*/

#projectOverview{
    display: flex;
    flex-direction: column;
    height: 100%;
    grid-row: 1/7;
    grid-column: 3/10;
    background-color: #131313;
    justify-content: center;
    align-items:start;
    position: relative;
    box-shadow: 0 0 15px #131313;
}

.projectHeading{
    position: absolute;
    margin: 0;
    color: #FF8B40;
    font-family: VCR;
    font-weight: lighter;
    padding: 15px 20px 15px 20px;
    border: solid #FF8B40 3px;
    background-color: rgba( 255, 139, 64, 0.2);
    box-shadow: 0 0 30px rgba( 255, 139, 64, 0.3);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    transition: all 0.05s linear;
}

#projectHeadingBottom:hover{
    color:#FFAC40;
    border: solid #FFAC40 3px;
    background-color: rgba( 255, 172, 64, 0.2);
    box-shadow: 0 0 30px rgba( 255, 172, 64, 0.3);
    transform: scale(1.05);
}

#projectHeadingTop{
    top: -25px;
    left: -15px;
    font-size: 1.2em;
    padding: 15px 20px 15px 20px;
}

#projectHeadingBottom{
    bottom: -25px;
    right :50px;
    font-size: 1em;
    padding: 12px 20px 12px 20px;
}

#overviewImage{
    height: 70%;
    width: auto;
    display: block;
    margin: auto auto auto auto;
}

#projectText{
    height: auto;
    background-color: none;
    margin: 0;
    padding: 0 60px 60px 60px;
}

#overviewImageContainer{
    display: flex;
    align-items: center;
    height: 70%;
    width: 100%;
}

#projectTextContainer{
    height: 30%;
    width: 100%;
}

/*--- Project List (Right Container)---*/

#projectList{
    display: grid;
    grid-row: 1/7;
    grid-column: 10/13;    
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    background-color: #131313;
    position: relative;
    box-shadow: 0 0 15px #131313;
    justify-items: center;
    align-items: center;
    gap: 0;
}

.listImage{
    display: block;
}

.listImageContainer{
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s linear;
}

.listImageContainer:hover{
    transform: scale(1.05);
    border: solid #FFAC40 3px;
    background-color: rgba( 255, 172, 64, 0.2);
    box-shadow: 0 0 60px rgba( 255, 172, 64, 0.3);
    cursor: pointer;
}

#project1{
    grid-row: 1/2;
    grid-column: 1/2;
}

#project2{
    grid-row: 1/2;
    grid-column: 2/3;
}

#project3{
    grid-row: 2/3;
    grid-column: 1/2;
}