@font-face {
    font-family: VCR;
    src: url(../../assets/fonts/VCR_OSD_MONO_1.001.ttf);
  }

#personalInfo{
}

/*--- About Me (Left Container)---*/

#about{
    grid-row: 1/7;
    grid-column: 3/10;
}

#firstName{
    text-shadow: 0 0 20px rgba( 240, 49, 0, 0.6);
}

#lastName{
    margin-left: 35px;
    text-shadow: 0 0 20px rgba( 240, 49, 0, 0.6);
}

#profilePic{
    width:400px;
    margin-left: 12vw;
}

#summaryBox{
    margin-bottom: 100px;
}

#summary{
    width: 41vw;
}

.aboutText{
    box-shadow: 0 0 25px #131313;
} 

/*--- Skills & Experience (Right Container)---*/

#skills{
    display: flex;
    flex-direction: column;
    grid-row: 1/7;
    grid-column: 10/13; 
    background-color: #131313;
    box-shadow: 0 0 25px #131313;
    justify-content: space-between;
}

h2{
    width: fit-content;
    font-family: VCR;
    font-weight: lighter;
    color: #FD4040;
    margin: 0;
}

#skillsHeading{
    position: absolute;
    margin-top: -20px;
    margin-left: -20px;
    padding: 15px 20px 15px 20px;
    border: solid #FD4040 3px;
    background-color: rgba( 240, 49, 0, 0.25);
    box-shadow: 0 0 30px rgba( 240, 49, 0, 0.5);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
}

#skillsText{
    padding-bottom: 0;
    margin-top: 20px;
    margin-bottom: 0;
}

#iconRow{
    display: flex;
    margin: 0;
    padding: 60px 60px 60px 60px;
    justify-content: start;
    gap: 20px;
}

.icons{
    width: 35px;
    filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(215deg) brightness(106%) contrast(100%);
}