@font-face {
    font-family: VCR;
    src: url(../../assets/fonts/VCR_OSD_MONO_1.001.ttf);
  }

a{
    text-decoration: none;
    color: inherit;
}

#navbar{
    overflow: hidden;
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: VCR;
    color: white;
    top: 0;
    padding-top: 6vh;
    padding-bottom: 6vh;
    z-index: 10;
    background-image: linear-gradient(#000000, rgba( 0, 0, 0, 0));
}

#leftNav{
    margin-left: 7vw;
}

#rightNav{
    display: flex;
    gap: 70px;
    margin-right: 7vw;
}
