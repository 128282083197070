@font-face {
    font-family: VCR;
    src: url(../../assets/fonts/VCR_OSD_MONO_1.001.ttf);
  }

.infoSection{
    display: flex;
    height: fit-content;
    width: 100%;
    justify-content:space-evenly;
    margin-bottom: 300px;
}

.infoTextContainer{
    height: auto;
    width: auto;
    margin: 35px 0 0 0;
}

.imageContainer{
    height: auto;
    width: 370px;
    margin: 0;
    position: relative;
}

#astroImage{
    position: absolute;
    width: 250px; 
    height: auto; 
    margin-left: 0; /* Reset margin */
    left: 90px;
}

#alienImage{
    position: absolute;
    width: 390px; 
    height: auto; 
    margin-left: 0; /* Reset margin */
    left: -30px;
}

.darkRectangle{
    position: absolute;
    z-index: -1;
    width: 320px;
    height: 240px;
    background-color: #131313;
    margin: 115px 0 0 50px;
    box-shadow: 0 0 7px #0D0D0D;
}

.darkRectangle2{
    position: absolute;
    z-index: -1;
    width: 340px;
    height: 280px;
    background-color: #0D0D0D;
    margin: 90px 0 0 0px;
    box-shadow: 0 0 7px #0D0D0D;
}

#yellowRectangle{
    position: absolute;
    z-index: -2;
    width: 320px;
    height: 160px;
    background-color: rgba( 255, 221, 64, 0.2);
    border: solid #FFDD40 3px;
    margin: 20px 0 0 30px;
    box-shadow: 0 0 30px rgba( 255, 221, 64, 0.3);
}

#purpleRectangle{
    position: absolute;
    z-index: -3;
    width: 180px;
    height: 250px;
    background-color: rgba( 172, 64, 255, 0.2);
    border: solid #AB40FE 3px;
    margin: 130px 0 0 0;
    box-shadow: 0 0 30px rgba( 172, 64, 255, 0.3);
}

#yellowRectangle2{
    position: absolute;
    z-index: -2;
    width: 200px;
    height: 330px;
    margin: 20px 0 0 200px;
    background-color: rgba( 255, 221, 64, 0.2);
    border: solid #FFDD40 3px;
    box-shadow: 0 0 30px rgba( 255, 221, 64, 0.3);
}

#purpleRectangle2{
    position: absolute;
    z-index: -3;
    width: 390px;
    height: 150px;
    margin: 260px 0 0 -25px;
    background-color: rgba( 172, 64, 255, 0.2);
    border: solid #AB40FE 3px;
    box-shadow: 0 0 30px rgba( 172, 64, 255, 0.3);
}

.infoTitle{
    position: absolute;
    width:fit-content;
    padding: 7px 30px 7px 30px;
    margin-top:0;
    margin-left: 45px;
    font-family: VCR;
    font-weight: lighter;
    font-size: 1.4em;
}

#titleYellow{
    color: #FFDD40;
    border: solid #FFDD40 2px;
    box-shadow: 0 0 30px rgba( 255, 221, 64, 0.3);
    background-color: rgba( 255, 221, 64, 0.2);
}

#titleBlue{
    color: #40FFC2;
    border: solid #40FFC2 2px;
    box-shadow: 0 0 30px rgba( 64, 255, 194, 0.3);
    background-color: rgba( 64, 255, 194, 0.2);
}

.infoText{
    font-family: "transat-text", sans-serif;
    font-weight: 500;
    font-style: normal;
    width: 500px;
    height: auto;
    color: white;
    background-color: #131313;
    padding: 60px;
    padding-top:  70px;
    margin-top:25px;
    box-shadow: 0 0 7px #0D0D0D;
}