#projectImageContainer{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    grid-row: 1/7;
    grid-column: 3/10;
}



#projectImage{
    height: 80%;
    width:100%;
}