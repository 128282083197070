@font-face {
    font-family: Geist Mono;
    src: url(../assets/fonts/GeistMono-Regular.otf);
  }

@font-face {
  font-family: VCR;
  src: url(../assets/fonts/VCR_OSD_MONO_1.001.ttf);
}
  
  body{
    background-image: url("../assets/images/GrainyBackground.webp");
    background-position: center;
    background-repeat: repeat;
    background-size:cover;
    background-attachment: fixed;
  }
  
  p{
    font-family: Geist Mono;
    background-color: #131313;
    padding: 60px;
    color: white;
    font-size: 0.9em;
  }
  

.content{
    display: grid;
    width: 100vw;
    height: 85vh;
    grid-template-rows: repeat(7, 1fr);
    grid-template-columns: repeat(14, 1fr);
    gap: 50px;
    margin-top: 15vh;
}