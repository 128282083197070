#projectSidebar{
    grid-row: 1/7;
    grid-column: 10/13; 
    background-color: #131313;
    box-shadow: 0 0 25px #131313;
}

#sidebarHeading{
    width: fit-content;
    font-family: VCR;
    font-weight: lighter;
    color: #FD4040;
    margin: 0;
    position: absolute;
    margin-top: -20px;
    margin-left: -20px;
    padding: 15px 20px 15px 20px;
    border: solid #FD4040 3px;
    background-color: rgba( 240, 49, 0, 0.25);
    box-shadow: 0 0 30px rgba( 240, 49, 0, 0.5);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
}

#projectSidebar a:link{
    color: #FF8B40;
}

#projectSidebar a:visited{
    color: #FF8B40;
}

#projectSidebar a:hover{
    color: #FFAC40;
}

