ion-icon {
    font-size: 15px;
    color: white;
  }

.arrows{
    width:20px;
    transition: transform 0.1s;
} 

.arrows:hover{
    transform: scale(1.20);
    cursor: pointer;
}

#carousel{
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 25vh auto 30vh auto;
    justify-content: center;
    align-items: center;
    position: relative;
}

#tvImage{
    width: 450px;
    height: auto;
    margin: 0 60px 0 60px;
    background-color: none;
}

#whiteBackground{
    position:absolute;
    z-index: -2;
    height: 350px;
    width: 310px;
    background-color: white;
}

#projectLink {
    position: absolute;
    z-index: 2;
    width: 200px; /* Match the image width */
    height: auto; /* Maintain aspect ratio */
    display: flex;
    margin-left: 0;
    top: 16%;
}

#projectXImage {
    height: auto;
    width: 100%;
    display: block;
}
